<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <b-container class="bg-white">
          <b-row class="mb-4">
            <b-col>
              <menu-workflow :activekey="'dashboard'" />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInputStart">from:</label>
                  <b-form-datepicker id="dateInputStart" class="mr-2" type="date" v-model="startDate" required> </b-form-datepicker>
                  <label class="mr-2" for="dateInputEnd">to:</label>
                  <b-form-datepicker id="dateInputEnd" class="mr-2" type="date" v-model="endDate" required> </b-form-datepicker>
                </b-form>
              <div v-if="loading">
                <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col md="12" lg="6">
              <div><strong>Approvers</strong></div>
              <div v-if="!loading">
                <div v-for="approver in approvers" :key="'approver-' + approver.approved_by">
                  {{approver.approved_by}}: {{approver.count}}
                </div>
              </div>
              <div v-if="loading">
                <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
              </div>
            </b-col>
            <b-col md="12" lg="6">
              <div><strong>Creators</strong></div>
              <div v-if="!loading">
                <div v-for="creator in creators" :key="'creator-' + creator.created_by">
                  {{creator.created_by}}: {{creator.count}}
                </div>
              </div>
              <div v-if="loading">
                <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col md="12" lg="6">
              <strong>Articles by workflow stage</strong>
              <div id="stages" style="width: 100%; height: 400px;"></div>
            </b-col>
            <b-col md="12" lg="6">
              <strong>Editors</strong>
              <div id="users" style="width: 100%; height: 400px;"></div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <strong>All editorial activies daily</strong>
              <div id="dailyActivites" style="width: 100%; height: 400px;"></div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <strong>Daily articles</strong>
              <div id="dailyArticles" style="width: 100%; height: 400px;"></div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import workflow from '@/workflows/article'
import ac from '@/libs/accesscontrol'
import MenuWorkflow from '@/components/workflow/article/Menu.vue'

import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
const themeContrast = { color: ['#00338D', '#EAAA00', '#005EB8', '#43B02A', '#0091DA', '#009A44', '#483698', '#470A68', '#6D2077', '#00A3A1'] }
echarts.registerTheme('kpmg', theme)
echarts.registerTheme('contrast', themeContrast)

export default {
  components: {
    MenuWorkflow
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
    this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
    this.endDate = moment().add(1, 'day').format('YYYY-MM-DD')
    this.load()
  },
  data () {
    return {
      approvers: [],
      startDate: null,
      endDate: null,
      loading: true,
      logs: [],
      moment: moment,
      permissions: {}
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      const dashboard = await this.$Amplify.API.get('cosmos', `/articles/dashboard/${this.startDate}/${this.endDate}`)
      this.loading = false
      this.approvers = dashboard.approvers
      this.creators = dashboard.creators
      const stages = dashboard.stages
      _.each(dashboard.stages, x => {
        x.name = workflow.find(y => y.id === x.stage).name
        x.value = parseInt(x.count)
      })
      const stagesSorted = _.reverse(_.orderBy(stages, ['stage']))
      console.log(stagesSorted)
      const stagesOptions = {
        dataset: {
          source: stagesSorted
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          left: '100px'
        },
        xAxis: {
          type: 'value',
          show: true,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        },
        series: [
          {
            name: 'total',
            type: 'bar',
            label: {
              show: true,
              position: 'left',
              formatter: '{b}'
            },
            encode: {
              x: 'value',
              y: 'name'
            }
          }
        ]
      }
      echarts.init(document.getElementById('stages'), 'kpmg').setOption(stagesOptions)
      const usersOptions = {
        dataset: {
          source: dashboard.users
        },
        series: [
          {
            name: 'editors',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['40%', '50%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              value: 'count',
              tooltop: 'username',
              itemName: 'username'
            }
          }
        ]
      }
      echarts.init(document.getElementById('users'), 'kpmg').setOption(usersOptions)
      const dailyActivitesOptions = {
        dataset: {
          source: dashboard.dailyActivity
        },
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'activities',
            type: 'line',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          }
        ]
      }
      echarts.init(document.getElementById('dailyActivites'), 'kpmg').setOption(dailyActivitesOptions)
      const dailyArticles = {
        dataset: [
          {
            id: 'created',
            source: dashboard.dailyArticlesCreated
          },
          {
            id: 'published',
            source: dashboard.dailyArticlesPublished
          }
        ],
        legend: {
          data: ['created', 'published']
        },
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'created',
            datasetId: 'created',
            type: 'bar',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          },
          {
            name: 'published',
            datasetId: 'published',
            type: 'bar',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          }
        ]
      }
      echarts.init(document.getElementById('dailyArticles'), 'contrast').setOption(dailyArticles)
    }
  },
  watch: {
    'startDate': 'load',
    'endDate': 'load'
  }
}
</script>

<style>
</style>
